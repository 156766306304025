import React, { ChangeEvent, useEffect, useState } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Loader from '../../components/Loader';
import NewOrderNotification from '../../components/NewOrderNotification';
import OrderCancelModal from '../../components/OrderCancelModal';
import { ORDER_KEY } from '../../global/constants';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import useRefresh from '../../global/hooks/useRefresh';
import faviconSvg from '../../global/media/favicon.svg';
import { StatusType } from '../../global/types';
import * as actions from '../OrdersPage/actions';
import * as historyActions from '../OrderHistoryPage/actions';
import { selectors } from '../OrdersPage/reducer';
import { selectors as selectorsHistory } from '../OrderHistoryPage/reducer';
import { selectors as OrderSelector } from './reducer';
import * as Styled from './OrderPage.styles';
import * as actionsOrder from './actions';
import { selectors as selectorsAnalogs } from '../AnalogsPage/reducer';
import * as actionsAnalogs from '../AnalogsPage/actions';
import {
  OrderTableData,
  OrderTableProductData,
  PaymentType,
  UserRole,
} from '../OrdersPage/types';
import DetailLeftBlock from './components/DetailLeftBlock';
import OrderProductsBlock from './components/OrderProductsBlock';
import { formatOrderInfo, getStrongRecipeProducts } from './helpers';
import StatusPharmacy from './components/StatusPharmacy';
import StatusOperator from './components/StatusOperator';
import Timer from './components/Timer';
import StrongRecipeNotificationModal from './components/StrongRecipeNotificationModal';
import AutoDeliveryModal from './components/AutoDeliveryModal';
import { selectors as selectorsLogin } from '../LoginPage/reducer';
import ActivePharmacistBlock from '../../components/ActivePharmacistBlock';
import arrow from '../../global/media/arrow-breadCrumbs.svg';
import InterPayReport from './components/interPayReport';
import { OrderPageState } from './types';
import {
  PaymentItems,
  PaymentOrder,
} from './components/StatusOperator/StatusOperator';

const OrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = getFromLocalStorage('role');
  const token = getFromLocalStorage('token');
  const education = history.location.pathname.includes('/test-orders');
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const questionsData = useSelector(selectorsAnalogs.questionsData);
  const [isAnalogModalOpen, setAnalogModalOpen] = useState<boolean>(false);
  const [statusActive, setStatusActive] = useState<boolean>(true);
  const [editOperatorActive, setEditOperatorActive] = useState<boolean>(true);
  const [cancelOrderActive, setCancelOrderActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmOrderOpen, setConfirmOrderOpen] = useState<boolean>(false);

  const [currentFunction, setCurrentFunction] = useState<string>('');

  const [isPharmacistOnOrderOpen, setPharmacistOnOrderOpen] =
    useState<boolean>(false);

  const [isAutoDeliveryModalOpen, setIsAutoDeliveryModalOpen] =
    useState<boolean>(false);
  const dataUser = useSelector(selectorsLogin.userData);
  const [n8nPrice, setN8nPrice] = useState<string>('');
  const data = useSelector(selectors.selectedOrderData);
  const orderDataLoading = useSelector(selectors.selectedOrderLoading);
  const newOrdersMuted = useSelector(selectors.notMutedOrders);
  const selectedActivePharmacist = useSelector(
    OrderSelector.selectedActivePharmacist
  );

  const imgUrl = useSelector(OrderSelector.recipeImg);
  const dataOrders = useSelector(selectors.ordersPageData);
  const interpayPayouts = useSelector(OrderSelector.interpayPayouts);

  const getCartHistory = () => {
    const token = getFromLocalStorage('accessToken');
    const orderId = history.location.pathname.split('/')[2];
    const createdAt = data?.originalCreatedAt;
    dispatch(
      historyActions.getOrderHistory.request({ orderId, token, createdAt })
    );
  };

  useEffect(() => {
    if (data && data.paymentType === 'interpay') {
      const orderId = history.location.pathname.split('/')[2];
      if (data) {
        getCartHistory();
        dispatch(actionsOrder.getInterpayPayouts.request({ orderId }));
      }
    }
  }, [data?.status, data?.payments?.order]);

  const id = history.location.pathname.split('/')[2];
  const dataNetwork = useSelector(selectors.allNetworkEnabledData);
  const [pharmacistOnOrder, setPharmacistOnOrder] = useState<{
    name: string;
    phone: string;
  } | null>(null);

  const hiddenFileInput = React.useRef(null);
  const [imgRecipe, setImgRecipe] = useState<any>(null);
  const [paid, setPaid] = useState<boolean>(data?.paid || false);
  const [paidDelivery, setPaidDelivery] = useState<boolean>(
    data?.paidDelivery || false
  );
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [isStrongRecipeModalOpen, setIsStrongRecipeModalOpen] = useState(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [loader, setLoader] = useState(false);
  const [isStrongRecipeProductsChecked, setIsStrongRecipeProductsChecked] =
    useState(false);
  const [promoCodeError, setPromoCodeError] = useState<string>('');

  const handlePromoCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value.toUpperCase());
  };

  const [openQuestion, setOpenQuestion] = useState<string[]>([]);

  const [beforePaidInterpay, setBeforePaidInterpay] = useState<
    OrderPageState['interpayPayouts'] | null
  >(null);
  const [afterPaidInterpay, setAfterPaidInterpay] = useState<
    OrderPageState['interpayPayouts'] | null
  >(null);

  useEffect(() => {
    setLoading(true);
    if (
      userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      dispatch(actions.getAllNetworkEnabled.request());
    }

    setLoading(false);
  }, []);

  const params: { orderId: string } = useParams();

  const handleApply = () => {
    if (promoCode) {
      setLoader(true);
      dispatch(
        actionsOrder.applyPromoCode.request({
          id: data?.id,
          data: promoCode,
          postEffect: () => {
            setLoader(false);
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id: data?.id,
              })
            );
          },
        })
      );
    }
  };
  const reissuedOrder =
    dataOrders && dataOrders.filter((order) => order.id === data?.reissued_to);

  const reissuedOrderFrom =
    dataOrders &&
    dataOrders.filter((order) => order.id === data?.reissued_from);

  const cancelPromocode = (id: string) => {
    setLoader(true);
    dispatch(
      actions.cancelPromocode.request({
        id,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              token,
              id: data?.id,
            })
          );
          setLoader(false);
        },
      })
    );
  };

  useEffect(() => {
    if (
      data?.products &&
      params.orderId === data?.id &&
      !orderDataLoading &&
      !isStrongRecipeProductsChecked &&
      !isStrongRecipeModalOpen &&
      process.env.REACT_APP_STRONG_RECIPE_MODAL_TEXT &&
      userRole === UserRole.PHARMACIST
    ) {
      const strongRecipeProducts: OrderTableProductData[] =
        getStrongRecipeProducts(data?.products);
      if (strongRecipeProducts.length > 0) {
        setIsStrongRecipeModalOpen(true);
        setIsStrongRecipeProductsChecked(true);
      }
    }
  }, [data?.products]);

  useEffect(() => {
    dataOrders &&
      dataOrders[0].test &&
      setToLocalStorage('testsOrders', dataOrders);
    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      dispatch(
        actionsAnalogs.getAnalogsListPharmacistBySku.request({ key: 'faq' })
      );
    }
    if (
      userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR
    ) {
      dispatch(
        actionsAnalogs.getAnalogsListPharmacistBySku.request({
          key: 'timer-messages',
        })
      );
    }
  }, []);

  useEffect(() => {
    data?.statusName === 'Расчет доставки' &&
      data?.deliveryType === 'delivery' &&
      setIsAutoDeliveryModalOpen(true);
    dispatch(actions.resetCoordinates());
  }, [data?.statusName]);

  useEffect(() => {
    const productId = !id ? getFromLocalStorage('selectedOrderId') : id;
    const currentProduct = !dataOrders
      ? getFromLocalStorage('testsOrders')?.find(
          (el: OrderTableData) => el.id === productId
        )
      : dataOrders?.find((el) => el.id === productId);
    if (
      !data?.viewed &&
      data?.orderNumber &&
      (userRole === UserRole.PHARMACIST ||
        userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER)
    ) {
      dispatch(
        actions.putSeenOrders.request({
          orderData: [data?.orderNumber.slice(1)],
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                id: !id ? getFromLocalStorage('selectedOrderId') : id,
                testOrders: currentProduct?.test || !!education,
                postEffect: () => setStatusActive(true),
              })
            );
          },
        })
      );
    }
  }, [data && data.viewed, data && data?.orderNumber]);

  const handleSetOrderPharmacist = (collector: {
    name: string;
    phone: string;
  }) => {
    dispatch(actionsOrder.setSelectedActivePharmacistOnOrder(collector));
    setPharmacistOnOrder(collector);
  };

  const handleOpenPharmacistOnOrder = (isOpen: boolean) => {
    setPharmacistOnOrderOpen(isOpen);
  };
  const handleSaveOrderPharmacist = () => {
    dispatch(
      actionsOrder.putOrder.request({
        orderInfo: pharmacistOnOrder,
        id: data?.id,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              token,
              id: data?.id,
              postEffect: () => {
                setPharmacistOnOrderOpen(false);

                currentFunction === 'ready'
                  ? dispatch(
                      actions.putReadyStatus.request({
                        id: data?.id,
                        token,
                        postEffect: () => {
                          dispatch(
                            actions.setSelectedOrder.request({
                              role,
                              id: data?.id,
                              testOrders: data?.test,
                            })
                          );
                          !education &&
                            dispatch(actions.getOrders.request({ status: '' }));
                        },
                      })
                    )
                  : dispatch(
                      actions.putCollectingStatus.request({
                        id: data?.id,
                        token,
                        postEffect: () => {
                          dispatch(
                            actions.setSelectedOrder.request({
                              role,
                              id: data?.id,
                              testOrders: data?.test,
                            })
                          );
                          !education &&
                            dispatch(actions.getOrders.request({ status: '' }));
                        },
                      })
                    );
                setCurrentFunction('');
              },
            })
          );
        },
      })
    );
  };
  const handleEmptyActiveUserClick = () => {
    handleOpenPharmacistOnOrder(false);
  };

  useEffect(() => {
    if (
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.ADMIN ||
      userRole === UserRole.HEAD_OPERATOR
    )
      setStatusActive(false);
  }, [userRole]);

  useEffect(() => {
    if (data) {
      setPaid(data.paid);
      setPaidDelivery(data.paidDelivery);
    }
  }, [data && data?.paid, data && data?.paidDelivery]);

  useEffect(() => {
    const productId = !id ? getFromLocalStorage('selectedOrderId') : id;

    const currentProduct = !dataOrders
      ? getFromLocalStorage('testsOrders')?.find(
          (el: OrderTableData) => el.id === productId
        )
      : dataOrders?.find((el) => el.id === productId);

    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      dispatch(
        actions.getNotMutedOrders.request({
          testOrders: currentProduct?.test || !!education,
        })
      );
    }
    dispatch(actions.clearSelectedOrder());
    setToLocalStorage('selectedOrder', {});
    dispatch(
      actions.setSelectedOrder.request({
        role,
        id: productId,
        testOrders: currentProduct?.test || !!education,
        postEffect: () => {
          setLoading(false);
        },
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (imgRecipe)
      dispatch(actionsOrder.postImg.request({ token, data: imgRecipe }));
  }, [dispatch, imgRecipe]);
  useRefresh(() => {
    const productId = !id ? getFromLocalStorage('selectedOrderId') : id;
    const currentProduct = !dataOrders
      ? getFromLocalStorage('testsOrders')?.find(
          (el: OrderTableData) => el.id === productId
        )
      : dataOrders?.find((el) => el.id === productId);
    if (
      ((userRole === UserRole.OPERATOR ||
        userRole === UserRole.ADMIN ||
        userRole === UserRole.HEAD_OPERATOR) &&
        editOperatorActive) ||
      (userRole !== UserRole.OPERATOR &&
        userRole !== UserRole.ADMIN &&
        userRole !== UserRole.HEAD_OPERATOR &&
        statusActive)
    ) {
      dispatch(
        actions.setSelectedOrder.request({
          role,
          id: !id ? getFromLocalStorage('selectedOrderId') : id,
          testOrders: currentProduct?.test || !!education,
        })
      );
    }

    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      dispatch(
        actions.getNotMutedOrders.request({
          testOrders: currentProduct?.test || !!education,
        })
      );
    }
  }, [dispatch, education]);

  const handleClick = () => {
    if (!editOperatorActive) {
      // @ts-ignore
      hiddenFileInput.current.click();
    }
  };

  const handleSetOperatorActive = () => {
    setEditOperatorActive(!editOperatorActive);
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setImgRecipe(fileUploaded);
  };

  const handleOpenAnalogModal = () => {
    setAnalogModalOpen(true);
  };

  const handleCloseAnalogModal = () => {
    setAnalogModalOpen(false);
    dispatch(actionsOrder.clearSelectedProduct());
  };

  const handleCLickSave = (setPayment?: boolean) => {
    const productId = !id ? getFromLocalStorage('selectedOrderId') : id;
    const currentProduct = !dataOrders
      ? getFromLocalStorage('testsOrders')?.find(
          (el: OrderTableData) => el.id === productId
        )
      : dataOrders?.find((el) => el.id === productId);
    const changedOrder = getFromLocalStorage(ORDER_KEY);
    const orderInfo =
      data && !setPayment
        ? formatOrderInfo(
            data.address ? data.address.id : 0,
            data.deliveryType,
            data.paymentType,
            !imgUrl ? '' : imgUrl,
            data?.source,
            paid,
            paidDelivery,
            changedOrder
          )
        : {
            paid,
            paid_delivery: paidDelivery,
          };
    if (data && n8nPrice) {
      dispatch(
        actionsOrder.n8nPrice.request({
          client_address: data.address?.address ? data.address.address : '',
          created_at: data.createdAt ? data.createdAt : '',
          entered_price: n8nPrice ? Number(n8nPrice) : 0,
          order_id: data.orderNumber.slice(1, data.orderNumber.length),
          pharmacy_address: data.sourceAddress ? data.sourceAddress : '',
          selected_price: data.delivery?.price ? data.delivery?.price : 0,
          slots: data.delivery?.slotsAll ? data.delivery?.slotsAll : [],
          delivery_slot: data.delivery?.slotsCurrent
            ? data.delivery?.slotsCurrent?.[0]
            : {},
          claim_price: data.delivery?.claimPrice
            ? data.delivery?.claimPrice
            : 0,
        })
      );
    }
    setSubmitForm(true);
    dispatch(
      actionsOrder.putOrder.request({
        orderInfo:
          !pharmacistOnOrder || !statusActive ? orderInfo : pharmacistOnOrder,
        id: !id ? getFromLocalStorage('selectedOrderId') : id,
        postEffect: () => {
          pharmacistOnOrder &&
            dispatch(actionsOrder.clearSelectedActivePharmacistOnOrder());
          if (
            userRole !== UserRole.OPERATOR &&
            userRole !== UserRole.HEAD_OPERATOR &&
            userRole !== UserRole.ADMIN &&
            !setPayment &&
            (statusActive ? !pharmacistOnOrder : true)
          ) {
            dispatch(
              actions.putClarificationStatus.request({
                id: data?.id,
                token,
                postEffect: () => {
                  dispatch(
                    actions.setSelectedOrder.request({
                      role,
                      id: !id ? getFromLocalStorage('selectedOrderId') : id,
                      testOrders: currentProduct?.test || !!education,
                      postEffect: () => setStatusActive(true),
                    })
                  );
                },
              })
            );
          }
          if (
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.ADMIN ||
            userRole === UserRole.HEAD_OPERATOR ||
            pharmacistOnOrder
          ) {
            setEditOperatorActive(true);
            dispatch(
              actions.setSelectedOrder.request({
                role,
                id: !id ? getFromLocalStorage('selectedOrderId') : id,
                testOrders: currentProduct?.test || !!education,
              })
            );
          } else setStatusActive(false);
        },
      })
    );
  };

  useEffect(() => {
    if (
      data &&
      selectedActivePharmacist &&
      !data.collector.phone &&
      data.status !== 'completed'
    ) {
      setPharmacistOnOrder(selectedActivePharmacist);
      pharmacistOnOrder && handleCLickSave();
    }
  });

  const handleDeclineOrder = (data: OrderTableData) => {
    setCancelOrderActive(false);
  };

  const handleSubmitOrder = (message: string, id: string) => {
    const productId = !id ? getFromLocalStorage('selectedOrderId') : id;
    const currentProduct = !dataOrders
      ? getFromLocalStorage('testsOrders')?.find(
          (el: OrderTableData) => el.id === productId
        )
      : dataOrders?.find((el) => el.id === productId);
    setCancelOrderActive(false);
    dispatch(
      actions.cancelOrder.request({
        message,
        id,
        postEffect: () => {
          history.push('/orders');
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: !id ? getFromLocalStorage('selectedOrderId') : id,
              testOrders: currentProduct?.test || !!education,
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    if (
      newOrdersMuted &&
      newOrdersMuted?.count > 0 &&
      (userRole === UserRole.PHARMACIST ||
        UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER)
    ) {
      setConfirmOrderOpen(true);
    } else setConfirmOrderOpen(false);
  }, [newOrdersMuted]);

  const handleChangePaymentMethod = () => {
    dispatch(
      actionsOrder.putOrder.request({
        orderInfo: {
          delivery_method:
            data?.deliveryType === 'self'
              ? data.deliveryType
              : 'delivery_yandex',
          payment_method:
            data?.paymentType === 'in_place' ? 'kaspi' : 'in_place',
        },
        id: data?.id,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              token,
              id: data?.id,
            })
          );
        },
      })
    );
  };

  const handleChangeDeliveryMethod = () => {
    dispatch(
      actionsOrder.putOrder.request({
        orderInfo: {
          delivery_method:
            data?.deliveryType === 'self' ? 'delivery_yandex' : 'self',
        },
        id: data?.id,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              token,
              id: data?.id,
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    const paidIndex = interpayPayouts.findIndex(
      (item) => item.status === 'paid'
    );
    const beforePaidInterpay =
      paidIndex !== -1
        ? interpayPayouts.slice(0, paidIndex + 1)
        : interpayPayouts;
    const afterPaidInterpay =
      paidIndex !== -1 ? interpayPayouts.slice(paidIndex + 1) : [];
    setBeforePaidInterpay(beforePaidInterpay);
    setAfterPaidInterpay(afterPaidInterpay);
  }, [interpayPayouts]);

  return (
    <>
      {!confirmOrderOpen && (
        <Helmet>
          <link rel="icon" type="image/png" href={faviconSvg} />
        </Helmet>
      )}
      {!data && <Loader />}
      {data && (
        <Styled.OrderContainer>
          <DetailLeftBlock
            cancelPromocode={cancelPromocode}
            handleApply={handleApply}
            setPromoCodeError={setPromoCodeError}
            promoCodeError={promoCodeError}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            handlePromoCodeChange={handlePromoCodeChange}
            data={data}
            handleChange={handleChange}
            handleClick={handleClick}
            imgRecipe={imgRecipe}
            hiddenFileInput={hiddenFileInput}
            handleChangePaymentMethod={handleChangePaymentMethod}
            handleChangeDeliveryMethod={handleChangeDeliveryMethod}
            reissuedOrder={reissuedOrder}
            reissuedOrderFrom={reissuedOrderFrom}
          />
          <Styled.OrderMainContainer>
            {!loading ? (
              <Styled.OrderDetailContainer>
                {userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  userRole !== UserRole.ADMIN &&
                  statusActive && (
                    <StatusPharmacy
                      setOpenQuestion={setOpenQuestion}
                      questionsData={
                        questionsData?.map(
                          (item) => JSON?.parse(item)?.question_ru
                        ) || []
                      }
                      onEdit={() => {
                        setStatusActive(false);
                        setEditOperatorActive(false);
                      }}
                      setIsPharmacistOnOrderOpen={setPharmacistOnOrderOpen}
                      setCurrentFunction={setCurrentFunction}
                      data={data}
                    >
                      <div
                        className={`${
                          (userRole === UserRole.OPERATOR ||
                            userRole === UserRole.HEAD_OPERATOR ||
                            userRole === UserRole.ADMIN) &&
                          editOperatorActive
                            ? 'edit-operator-active'
                            : 'edit-operator'
                        }`}
                      >
                        {(userRole === UserRole.MANAGER ||
                          userRole === UserRole.PHARMACIST) && (
                          <>
                            <PaymentOrder
                              id={data?.id}
                              data={data.payments?.order}
                              itemsData={data.payments?.items}
                              deliveryData={data.payments?.delivery}
                              setLoader={setLoader}
                              noDelivery={data.deliveryType === 'self'}
                              DeliveryComponent={() => (
                                <div
                                  className="btn"
                                  onClick={() =>
                                    setIsAutoDeliveryModalOpen(true)
                                  }
                                >
                                  Вызов курьера
                                </div>
                              )}
                              user={userRole}
                            />
                            {data.paymentType !== PaymentType.INTERPAY && (
                              <PaymentItems
                                id={data?.id}
                                data={data.payments?.items}
                                defaultPaymentStatus={data.paymentStatus || ''}
                                setLoader={setLoader}
                                user={userRole}
                              />
                            )}
                          </>
                        )}
                        <OrderProductsBlock
                          data={data}
                          handleOpenAnalogModal={handleOpenAnalogModal}
                          handleCloseAnalogModal={handleCloseAnalogModal}
                          isAnalogModalOpen={isAnalogModalOpen}
                          handleCLickSave={handleCLickSave}
                          editActive={statusActive}
                          editOperator={editOperatorActive}
                          onEditOperator={handleSetOperatorActive}
                          setSubmitForm={setSubmitForm}
                          submitForm={submitForm}
                        />
                      </div>
                    </StatusPharmacy>
                  )}
                {!statusActive &&
                  userRole !== UserRole.OPERATOR &&
                  userRole !== UserRole.HEAD_OPERATOR &&
                  userRole !== UserRole.ADMIN && (
                    <div className="edit-title">
                      {t('OrderPage.EDIT_TITLE')}
                    </div>
                  )}
                {(userRole === UserRole.OPERATOR ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.ADMIN) && (
                  <div className="cancel-order-container">
                    {(userRole === UserRole.OPERATOR ||
                      userRole === UserRole.HEAD_OPERATOR ||
                      userRole === UserRole.ADMIN) && (
                      <div className="status-timer-block">
                        <StatusOperator
                          dataNetwork={dataNetwork}
                          setLoader={setLoader}
                          onCancelClick={() => setCancelOrderActive(true)}
                          data={data}
                          setIsAutoDeliveryModalOpen={
                            setIsAutoDeliveryModalOpen
                          }
                        />
                        {data.status !== StatusType.COMPLETED &&
                          data.status !== StatusType.CANCELED && (
                            <Timer
                              data={data}
                              messageData={questionsData || []}
                            />
                          )}
                      </div>
                    )}
                  </div>
                )}
                {interpayPayouts &&
                  beforePaidInterpay &&
                  interpayPayouts.length > 0 &&
                  data &&
                  data.paymentType === 'interpay' &&
                  (userRole === UserRole.ADMIN ||
                    userRole === UserRole.OPERATOR ||
                    userRole === UserRole.MANAGER) && (
                    <>
                      <InterPayReport
                        interpayPayouts={beforePaidInterpay}
                        orderId={history.location.pathname.split('/')[2]}
                        isFirst
                      />
                      {afterPaidInterpay && afterPaidInterpay.length > 0 && (
                        <InterPayReport
                          interpayPayouts={afterPaidInterpay}
                          orderId={history.location.pathname.split('/')[2]}
                          isFirst={false}
                        />
                      )}
                    </>
                  )}
                {(userRole === UserRole.OPERATOR ||
                  userRole === UserRole.HEAD_OPERATOR ||
                  userRole === UserRole.ADMIN ||
                  !statusActive) && (
                  <div
                    className={`${
                      (userRole === UserRole.OPERATOR ||
                        userRole === UserRole.HEAD_OPERATOR ||
                        userRole === UserRole.ADMIN) &&
                      editOperatorActive
                        ? 'edit-operator-active'
                        : 'edit-operator'
                    }`}
                  >
                    <OrderProductsBlock
                      data={data}
                      handleOpenAnalogModal={handleOpenAnalogModal}
                      handleCloseAnalogModal={handleCloseAnalogModal}
                      isAnalogModalOpen={isAnalogModalOpen}
                      handleCLickSave={handleCLickSave}
                      editActive={statusActive}
                      editOperator={editOperatorActive}
                      onEditOperator={handleSetOperatorActive}
                      setSubmitForm={setSubmitForm}
                      submitForm={submitForm}
                    />
                  </div>
                )}

                {(userRole === UserRole.PHARMACIST ||
                  userRole === UserRole.MANAGER ||
                  userRole === UserRole.PHARMACY_MANAGER) && (
                  <>
                    {' '}
                    <div className="title">Вопросы и ответы:</div>
                    <ul className="list" id="questions">
                      {questionsData?.map((item) => {
                        try {
                          const parsedItem = JSON.parse(item);
                          return (
                            <li>
                              <div
                                className="flex-container"
                                onClick={() =>
                                  setOpenQuestion((prevState) =>
                                    prevState.includes(parsedItem.question_ru)
                                      ? prevState.filter(
                                          (question) =>
                                            question !== parsedItem.question_ru
                                        )
                                      : [...prevState, parsedItem.question_ru]
                                  )
                                }
                              >
                                <div className="subtitle">
                                  {' '}
                                  {parsedItem?.question_ru}
                                </div>
                                <img
                                  src={arrow}
                                  alt="arrow"
                                  className={
                                    openQuestion.includes(
                                      parsedItem?.question_ru
                                    )
                                      ? 'arrow-active'
                                      : 'arrow'
                                  }
                                />
                              </div>
                              {openQuestion.includes(
                                parsedItem?.question_ru
                              ) && <div>{parsedItem?.answer_ru}</div>}
                            </li>
                          );
                        } catch (error) {
                          return null;
                        }
                      })}
                    </ul>
                  </>
                )}
              </Styled.OrderDetailContainer>
            ) : (
              <CircularProgress size={25} style={{ marginLeft: '32px' }} />
            )}
          </Styled.OrderMainContainer>
        </Styled.OrderContainer>
      )}
      {cancelOrderActive && (
        <OrderCancelModal
          onDecline={handleDeclineOrder}
          onSubmit={handleSubmitOrder}
          data={data}
        />
      )}
      {(userRole === UserRole.PHARMACIST ||
        userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER) &&
        confirmOrderOpen &&
        !loading && (
          <NewOrderNotification
            onClose={() => setConfirmOrderOpen(false)}
            newOrders={newOrdersMuted}
          />
        )}
      {isStrongRecipeModalOpen && (
        <StrongRecipeNotificationModal
          onClose={() => setIsStrongRecipeModalOpen(false)}
        />
      )}
      {(userRole === UserRole.OPERATOR ||
        userRole === UserRole.ADMIN ||
        userRole === UserRole.HEAD_OPERATOR) &&
        isAutoDeliveryModalOpen &&
        data &&
        dataUser && (
          <AutoDeliveryModal
            cancelPromocode={cancelPromocode}
            handleApply={handleApply}
            promoCodeError={promoCodeError}
            promoCode={promoCode}
            handlePromoCodeChange={handlePromoCodeChange}
            userPhone={dataUser.phone}
            userRole={dataUser.role}
            data={data}
            onClose={() => setIsAutoDeliveryModalOpen(false)}
            setN8nPrice={setN8nPrice}
            n8nPrice={n8nPrice}
            setLoader={setLoader}
            loader={loader}
          />
        )}

      {isPharmacistOnOrderOpen && (
        <ActivePharmacistBlock
          variant="isPharmacistOnOrder"
          onModalClose={handleOpenPharmacistOnOrder}
          onSetOrderPharmacist={handleSetOrderPharmacist}
          onSaveOrderPharmacist={handleSaveOrderPharmacist}
          pharmacistOnOrder={pharmacistOnOrder}
          orderId={data?.orderNumber}
          onEmptyActiveUsersClick={handleEmptyActiveUserClick}
        />
      )}
    </>
  );
};

export default OrderPage;
