import ru from 'date-fns/locale/ru';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import CustomToggle from '../../../../components/CustomToggle';
import { formatTime, getFromLocalStorage } from '../../../../global/helpers';
import { UserRole } from '../../../OrdersPage/types';
import * as actions from '../../actions';
import { formatDataForReport } from '../../helpers';
import networksArrow from '../../../../global/media/networks-arrow.svg';
import SearchModal from '../../../../components/SearchModal';
import { selectors as orderSelectors } from '../../../OrderPage/reducer';
import * as Styled from './RangeBlock.styles';

registerLocale('ru', ru);

interface RangeBlockParam {
  network: { code: string; name: string };
  statuses: string[];
  checked: boolean;
  setChecked: (e: boolean) => void;
  handleSetNetwork: (code: string, name: string) => void;
  networks:
    | {
        code: string;
        name: string;
      }[]
    | undefined;
  statusesArray: {
    code: string;
    name: string;
  }[];
  handleAddStatus: (code: string, name: string) => void;
}

const RangeBlock = ({
  network,
  statuses,
  checked,
  setChecked,
  handleSetNetwork,
  networks,
  statusesArray,
  handleAddStatus,
}: RangeBlockParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [errorTooltip, setErrorTooltip] = useState<boolean>(false);

  const [pdfType, setPdf] = useState<boolean>(false);
  const [xlsxType, setXlsx] = useState<boolean>(false);
  const [networksOpen, setNetworksOpen] = useState<boolean>(false);
  const [statusesOpen, setStatusesOpen] = useState<boolean>(false);
  const allStatuses = useSelector(orderSelectors.allStatuses);
  const pharmacyStatuses = useSelector(orderSelectors.allPharmacyStatuses);

  const role = getFromLocalStorage('role');

  useEffect(() => {
    if (startDate && endDate) setErrorTooltip(false);
  }, [startDate, endDate]);

  const getTodayReport = () => {
    const date = new Date(Date.now());
    const dataToday = new Date(date.setHours(0, 0, 0, 0));
    const dataTomorrow = new Date(new Date(date.setDate(date.getDate() + 1)));

    dispatch(
      actions.generateReport.request({
        data: formatDataForReport(
          dataToday,
          dataTomorrow,
          network.code,
          ['completed'],
          role
        ),
        pdf: false,
        xls: true,
      })
    );
  };

  const getYesterdayReport = () => {
    const date = new Date(Date.now());
    const dataToday = new Date(date.setHours(0, 0, 0, 0));
    const dataYesterday = new Date(new Date(date.setDate(date.getDate() - 1)));

    dispatch(
      actions.generateReport.request({
        data: formatDataForReport(
          dataYesterday,
          dataToday,
          network.code,
          ['completed'],
          role
        ),
        pdf: false,
        xls: true,
      })
    );
  };

  const handleSentReport = () => {
    if (startDate && endDate && (pdfType || xlsxType)) {
      dispatch(
        actions.generateReport.request({
          data: formatDataForReport(
            new Date(startDate),
            new Date(endDate),
            network.code,
            // eslint-disable-next-line
            checked
              ? ['completed']
              : statuses.find((el) => el === '')
              ? null
              : statuses
          ),
          pdf: pdfType,
          xls: xlsxType,
        })
      );
    } else setErrorTooltip(true);
  };

  const handleSetPdfType = (e: boolean) => {
    setPdf(e);
    setXlsx(!e);
  };

  const handleSetXlsType = (e: boolean) => {
    setXlsx(e);
    setPdf(!e);
  };

  const handleSetCheckbox = (e: boolean) => {
    setChecked(e);
  };

  return (
    <Styled.MainContainer>
      {role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR ? (
        <div className="report-btns-container">
          <Button
            text={t('ReportPage.GENERATE_REPORT_YERSTERDAY')}
            onClick={getYesterdayReport}
          />
          <Button
            text={t('ReportPage.GENERATE_REPORT_TODAY')}
            onClick={getTodayReport}
          />
        </div>
      ) : (
        <>
          <Styled.PickersBlock>
            <Styled.Picker>
              {role === UserRole.ADMIN && (
                <Styled.NetworkBlock>
                  <div
                    className="selected-network"
                    onClick={() => setNetworksOpen(!networksOpen)}
                  >
                    {network.name}
                    <img src={networksArrow} alt="networks-arrow" />
                  </div>
                  {networksOpen && (
                    <SearchModal
                      onClose={() => setNetworksOpen(false)}
                      data={networks}
                      setData={handleSetNetwork}
                      variant="networks"
                    />
                  )}
                </Styled.NetworkBlock>
              )}
              <div className="label">{t('ReportPage.START_DATE_LABEL')}</div>
              <div className="day">{startDate && formatTime(startDate)}</div>
              <DatePicker
                locale="ru"
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                timeInputLabel="Время:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                inline
              />
            </Styled.Picker>
            <Styled.Picker>
              {!checked &&
                role !== UserRole.OPERATOR &&
                role !== UserRole.HEAD_OPERATOR && (
                  <Styled.NetworkBlock>
                    <div
                      className="selected-network"
                      onClick={() => setStatusesOpen(!statusesOpen)}
                    >
                      {statusesArray.map((el) => el.name).toString()}
                      <img src={networksArrow} alt="networks-arrow" />
                    </div>
                    {statusesOpen && (
                      <SearchModal
                        onClose={() => setStatusesOpen(false)}
                        data={
                          role === UserRole.ADMIN
                            ? allStatuses
                            : pharmacyStatuses
                        }
                        setData={handleAddStatus}
                        dataArray={statusesArray}
                        variant="statuses"
                      />
                    )}
                  </Styled.NetworkBlock>
                )}
              <div className="label">{t('ReportPage.END_DATE_LABEL')}</div>
              <div className="day">{endDate && formatTime(endDate)}</div>
              <DatePicker
                locale="ru"
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
                timeInputLabel="Время:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                inline
              />
            </Styled.Picker>
          </Styled.PickersBlock>
          <Styled.GenerateButton>
            {errorTooltip && (
              <div className="error-tooltip">
                {t('ReportPage.ERROR_TOOLTIP')}
              </div>
            )}
            <CustomCheckbox
              checked={checked}
              onChange={(e) => handleSetCheckbox(e)}
              id="checkbox"
              title={t('ReportPage.CHECKBOX_TITLE')}
            />
            <Button
              text={t('ReportPage.GENERATE_INVOICE_BTN')}
              onClick={handleSentReport}
            />
            <Styled.TogglesBlock>
              <CustomToggle
                onChange={(e) => handleSetPdfType(e)}
                id="pdf"
                checked={pdfType}
                variant="standart"
                title=".pdf"
              />
              <CustomToggle
                onChange={(e) => handleSetXlsType(e)}
                id="xls"
                checked={xlsxType}
                variant="standart"
                title=".xlsx"
              />
            </Styled.TogglesBlock>
          </Styled.GenerateButton>
        </>
      )}
    </Styled.MainContainer>
  );
};

export default RangeBlock;
