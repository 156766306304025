import { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as actions from '../../actions';
import { selectors } from '../../reducer';
import { selectors as userSelector } from '../../../LoginPage/reducer';

import * as Styled from './RefundsBlock.styles';
import { UserRole } from '../../../OrdersPage/types';

interface RefundsBlockParam {
  network: string;
}

const RefundsBlock = ({ network }: RefundsBlockParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const payments = useSelector(selectors.payments);
  const refunds = useSelector(selectors.refunds);
  const adminPayments = useSelector(selectors.adminPayments);
  const adminRefunds = useSelector(selectors.adminRefunds);
  const userData = useSelector(userSelector.userData);

  useEffect(() => {
    if (userData) {
      if (userData.role === UserRole.ADMIN) {
        dispatch(
          actions.getPayments.request({
            role: UserRole.ADMIN,
          })
        );
        dispatch(
          actions.getRefunds.request({
            role: UserRole.ADMIN,
          })
        );
      } else if (userData.role === UserRole.MANAGER) {
        dispatch(
          actions.getPayments.request({
            network_code: userData.networkCode,
          })
        );
        dispatch(
          actions.getRefunds.request({
            network_code: userData.networkCode,
          })
        );
      }
    }
  }, [userData]);

  return (
    <Styled.RefundsReportContainer>
      <Typography variant="h6" marginBottom="20px">
        {t('ReportPage.PAYMENTS_TITLE')}
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 550, tableLayout: 'fixed' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell width="25%">
                {t('ReportPage.PAYMENTS_AND_REFUNDS')}
              </TableCell>
              {userData?.role === UserRole.ADMIN && (
                <TableCell width="20%" align="center">
                  {t('ReportPage.PHARMACY')}
                </TableCell>
              )}
              <TableCell
                width={userData?.role === UserRole.ADMIN ? '10%' : '15%'}
                align="center"
              >
                {t('ReportPage.SUM')}
              </TableCell>
              <TableCell
                width={userData?.role === UserRole.ADMIN ? '10%' : '15%'}
                align="center"
              >
                {t('ReportPage.DATE')}
              </TableCell>
              <TableCell
                width={userData?.role === UserRole.ADMIN ? '35%' : '45%'}
                align="center"
              >
                {t('ReportPage.ORDER')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(adminPayments || payments) && (
              <TableRow>
                <TableCell width="25%">
                  {t('ReportPage.WAITING_PAYMENT')}
                </TableCell>
                <TableCell colSpan={4} sx={{ width: '75%' }}>
                  {adminPayments
                    ? adminPayments.map((row, ind) => (
                        <TableRow
                          sx={{
                            borderBottom:
                              ind + 1 === adminPayments.length
                                ? 'solid white'
                                : '',
                          }}
                        >
                          <TableCell width="25%" align="center">
                            {row.network_code}
                          </TableCell>
                          <TableCell width="75%" colSpan={3}>
                            {row.reports.map((rep, ind) => (
                              <TableRow
                                sx={{
                                  display: 'table',
                                  width: '100%',
                                  borderBottom:
                                    ind + 1 === row.reports.length
                                      ? 'solid white'
                                      : '',
                                }}
                              >
                                <TableCell align="center" width="18%">
                                  {rep.total_sum}
                                </TableCell>
                                <TableCell align="center" width="20%">
                                  {new Date(
                                    rep.payout_date
                                  ).toLocaleDateString()}
                                </TableCell>
                                <TableCell width="62%">
                                  {rep.reports.map((order) => (
                                    <span>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          history.push(
                                            `/orders/${order.order_id}`
                                          )
                                        }
                                        className="orderBtn"
                                      >
                                        {order.order_id}
                                      </button>
                                    </span>
                                  ))}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))
                    : payments!.map((row, ind) => (
                        <TableRow
                          sx={{
                            borderBottom:
                              ind + 1 === payments!.length ? 'solid white' : '',
                          }}
                        >
                          <TableCell align="center" width="18%">
                            {row.total_sum}
                          </TableCell>
                          <TableCell align="center" width="22%">
                            {new Date(row.payout_date).toLocaleDateString()}
                          </TableCell>
                          <TableCell width="60%">
                            {row.reports.map((el) => (
                              <span>
                                <button
                                  type="button"
                                  onClick={() =>
                                    history.push(`/orders/${el.order_id}`)
                                  }
                                  className="orderBtn"
                                >
                                  {el.order_id}
                                </button>
                              </span>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableCell>
              </TableRow>
            )}

            {(adminRefunds || refunds) && (
              <TableRow>
                <TableCell width="25%">
                  {t('ReportPage.WAITING_REFUNDS')}
                </TableCell>
                <TableCell colSpan={4} width="75%">
                  {adminRefunds
                    ? adminRefunds.map((row, ind) => (
                        <TableRow
                          sx={{
                            width: '100%',
                            display: 'table',
                            borderBottom:
                              ind + 1 === adminRefunds.length
                                ? 'solid white'
                                : '',
                          }}
                        >
                          <TableCell width="25%" align="center">
                            {row.network_code}
                          </TableCell>
                          <TableCell width="75%" colSpan={3}>
                            {row.reports.map((rep, ind) => (
                              <TableRow
                                sx={{
                                  width: '100%',
                                  display: 'table',
                                  borderBottom:
                                    ind + 1 === row.reports.length
                                      ? 'solid white'
                                      : '',
                                }}
                              >
                                <TableCell align="center" width="18%">
                                  {rep.total_sum}
                                </TableCell>
                                <TableCell align="center" width="20%">
                                  {new Date(
                                    rep.payout_date
                                  ).toLocaleDateString()}
                                </TableCell>
                                <TableCell width="62%">
                                  {rep.reports.map((order) => (
                                    <span>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          history.push(
                                            `/orders/${order.order_id}`
                                          )
                                        }
                                        className="orderBtn"
                                      >
                                        {order.order_id}
                                      </button>
                                    </span>
                                  ))}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))
                    : refunds!.map((refund, ind) => (
                        <TableRow
                          sx={{
                            borderBottom:
                              ind + 1 === refunds!.length ? 'solid white' : '',
                          }}
                        >
                          <TableCell align="center" width="18%">
                            {refund.total_sum}
                          </TableCell>
                          <TableCell align="center" width="22%">
                            {t('ReportPage.PATMENTS_DATE')}
                          </TableCell>
                          <TableCell width="60%">
                            {refund.reports &&
                              refund.reports.map((el, index) => (
                                <span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      history.push(`/orders/${el.order_id}`)
                                    }
                                    className="orderBtn"
                                  >
                                    {el.order_id}
                                  </button>
                                </span>
                              ))}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Styled.RefundsReportContainer>
  );
};

export default RefundsBlock;
