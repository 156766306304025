import React, { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Styled from './DetailLeftBlock.styles';
import { needsRecipe } from '../../helpers';
import pencilIcon from '../../../../global/media/pencil.svg';
import Button from '../../../../components/Button';
import * as actions from '../../../OrdersPage/actions';
import success from '../../../../global/media/Vector (2).svg';
import editIcon from '../../../../global/media/edit-pencil.svg';
import bottomArrow from '../../../../global/media/bottom-arrow.svg';
import bottomArrowRed from '../../../../global/media/bottom-arrow-red.svg';
import * as actionsOrder from '../../actions';
import {
  DeliveryOptionData,
  DeliveryType,
  OrderTableData,
  PaymentType,
  UserRole,
} from '../../../OrdersPage/types';
import { selectors } from '../../reducer';
import { PharmacyTag } from '../../../SourcePage/types';
import {
  getFromLocalStorage,
  formatPhoneNumberWhatsappToBeValid,
  formatKaspiNumberToBeValid,
} from '../../../../global/helpers';
import * as actionsNotification from '../../../InitComponent/actions';
import copyIcon from '../../../../global/media/copy-icon.png';
import CustomDropDown from '../../../../components/CustomDropDown';
import { isDateFresh } from '../../../SalesPage/helpers';

interface DetailLeftBlockProp {
  data: OrderTableData;
  reissuedOrder: OrderTableData[] | null;
  reissuedOrderFrom: OrderTableData[] | null;
  imgRecipe: any;
  hiddenFileInput: any;
  handleChange: (event: any) => void;
  handleClick: () => void;
  handleChangePaymentMethod: () => void;
  handleChangeDeliveryMethod: () => void;
  handleApply: () => void;
  setPromoCodeError: React.Dispatch<React.SetStateAction<string>>;
  promoCodeError: string;
  setPromoCode: React.Dispatch<React.SetStateAction<string>>;
  handlePromoCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  promoCode: string;
  cancelPromocode: (id: string) => void;
}

const DetailLeftBlock = ({
  data,
  handleClick,
  imgRecipe,
  handleChange,
  hiddenFileInput,
  handleChangePaymentMethod,
  handleChangeDeliveryMethod,
  handleApply,
  setPromoCodeError,
  promoCodeError,
  setPromoCode,
  promoCode,
  handlePromoCodeChange,
  cancelPromocode,
  reissuedOrder,
  reissuedOrderFrom,
}: DetailLeftBlockProp) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const role = getFromLocalStorage('role');
  const token = getFromLocalStorage('token');
  const imgLoading = useSelector(selectors.recipeImgLoading);
  const dataImg = useSelector(selectors.recipeImg);
  const promoCodeApply = useSelector(selectors.promoCode);

  const [isEditingPaymentMethod, setIsEditingPaymentMethod] =
    useState<boolean>(false);
  const [isEditingDeliveryMethod, setIsEditingDeliveryMethod] =
    useState<boolean>(false);
  const [whatsappNumber, setWhatsappNumber] = useState<string>('+');
  const [kaspiNumber, setKaspiNumber] = useState<string>('+');
  const [isOrdersVisible, setIsOrdersVisible] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorKaspi, setErrorKaspi] = useState<boolean>(false);
  const [isWhatsappEditorActive, setIsWhatsappEditorActive] =
    useState<boolean>(false);

  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);

  const [isKaspiEditorActive, setIsKaspiEditorActive] =
    useState<boolean>(false);

  const handleCopyText = (text: string, content: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      actionsNotification.addApplicationNotification({
        content,
        canBeClosed: true,
        type: 'success',
      })
    );
  };

  const errors = {
    not_exist: 'OrdersPage.OrderDetailedModal.PROMO_CODE_NOT_EXIST',
    expired: 'OrdersPage.OrderDetailedModal.PROMO_CODE_EXPIRED',
    not_started: 'OrdersPage.OrderDetailedModal.PROMO_CODE_NOT_STARTED',
    below_min_price:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_BELLOW_MIN_PRICE',
    exceeded_max_usages:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_EXCEEDED_MAX_USAGES',
    exceeded_max_usages_per_user:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_EXCEEDED_MAX_USAGES_PER_USER',
    unavailable_for_user:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_UNAVAILABLE_FOR_USER',
    max_orders_exceeded:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_MAX_ORDER_EXCEEDED',
    only_for_delivery:
      'OrdersPage.OrderDetailedModal.PROMO_CODE_ONLY_FOR_DELIVERY',
    already_linked: 'OrdersPage.OrderDetailedModal.PROMO_CODE_ALREADY_LINKED',
  };

  useEffect(() => {
    if (data?.whatsappNumber) {
      setWhatsappNumber(`+${data?.whatsappNumber}`);
    }
    if (data?.payer?.phone) {
      setKaspiNumber(`+${data?.payer?.phone}`);
    }
  }, []);

  useEffect(() => {
    setPromoCodeError(
      t(errors[promoCodeApply.data as keyof typeof errors] || '')
    );
  }, [promoCodeApply]);

  useEffect(() => {
    if (promoCodeError) setPromoCodeError('');
  }, [promoCode]);

  const typeDelivery = (type?: string) => {
    switch (type) {
      case 'ondemand':
        return 'на машине';
      case 'slot':
        return '-';
      case 'both':
        return '-';
      case 'pedestrian':
        return 'курьер';

      default:
        return 'курьер';
    }
  };

  const deliveryOptionBlock = (deliveryOption: DeliveryOptionData) => (
    <div className="delivery-option-block">
      <div className="delivery-option__delivery-type-title delivery-option__title">
        {t('OrdersPage.OrderDetailedModal.DELIVERY_TYPE')} <br />
        <span className="delivery-option__delivery-type-value delivery-option__value">
          {typeDelivery(deliveryOption.deliveryType)}
        </span>
      </div>
      <div className="delivery-option__eta-title delivery-option__title">
        {t('OrdersPage.OrderDetailedModal.DELIVERY_AWAIT')}
        <br />
        <span className="delivery-option__eta-value delivery-option__value">
          {deliveryOption.eta} {t('OrdersPage.OrderDetailedModal.MINUTES')} -{' '}
          {deliveryOption.eta + 30} {t('OrdersPage.OrderDetailedModal.MINUTES')}
        </span>
      </div>
      <div className="delivery-option__price-title delivery-option__title">
        {t('OrdersPage.OrderDetailedModal.DELIVERY_PRICE')} <br />
        <span className="delivery-option__price-value delivery-option__value">
          {deliveryOption.price} ₸
        </span>
      </div>
      <div className="delivery-option__price-title delivery-option__title">
        {t('OrdersPage.OrderDetailedModal.PROVIDER')}
        <br />
        <span className="delivery-option__price-value delivery-option__value">
          {deliveryOption.provider}{' '}
        </span>
      </div>
    </div>
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(false);
    // @ts-ignore
    const phoneInputValue = event.target.value.replaceAll(/[\s-()]/g, '');
    if (!phoneInputValue.match(/^\+/)) {
      return;
    }
    setWhatsappNumber(phoneInputValue);
  };

  const handleInputKaspiChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (errorKaspi) setErrorKaspi(false);
    // @ts-ignore
    const phoneInputValue = event.target.value.replaceAll(/[\s-()]/g, '');
    if (!phoneInputValue.match(/^\+/)) {
      return;
    }
    setKaspiNumber(phoneInputValue);
  };

  const saveSwNumber = () => {
    if (formatPhoneNumberWhatsappToBeValid(whatsappNumber)) {
      dispatch(
        actionsOrder.putOrder.request({
          orderInfo: {
            whatsapp_number: whatsappNumber,
          },
          id: data?.id,
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id: data?.id,
              })
            );
          },
        })
      );
      setIsWhatsappEditorActive(false);
    } else {
      setError(true);
    }
  };
  const saveKaspiNumber = () => {
    if (formatKaspiNumberToBeValid(kaspiNumber)) {
      dispatch(
        actionsOrder.putOrder.request({
          orderInfo: {
            payer: { phone: kaspiNumber },
          },
          id: data?.id,
          postEffect: () => {
            dispatch(
              actions.setSelectedOrder.request({
                role,
                token,
                id: data?.id,
              })
            );
          },
        })
      );
      setIsKaspiEditorActive(false);
    } else {
      setErrorKaspi(true);
    }
  };

  const deliveryTypeTitlesStrategy = {
    [DeliveryType.DELIVERY]: t('OrdersPage.OrderDetailedModal.DELIVERY_TITLE'),
    [DeliveryType.SELF]: t('OrdersPage.OrderDetailedModal.SELF_DELIVERY_TITLE'),
  };

  const paymentTypeTitlesStrategy = {
    [PaymentType.IN_PLACE]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.SOURCE'),
    [PaymentType.KASPI_PAY]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.DARIBAR'),
    [PaymentType.INTERPAY]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'),
  };

  const isCollectorVisible =
    role === UserRole.PHARMACIST ||
    role === UserRole.MANAGER ||
    role === UserRole.PHARMACY_MANAGER;

  const suggestionOptions = [
    { name: 'Онлайн', value: PaymentType.KASPI_PAY },
    { name: 'На месте', value: PaymentType.IN_PLACE },
  ];
  const suggestionOptionsDelivery = [
    { name: 'Курьер', value: DeliveryType.DELIVERY },
    { name: 'Самовывоз', value: DeliveryType.SELF },
  ];
  const information = data?.address
    ? `  телефон: ${data?.phone},
  номер заказа: ${data?.orderNumber},
  город: ${data?.address?.city},
  улица-${data?.address?.address}, вход-${data?.address?.entrance},
  квартира-${data?.address?.flat}, этаж-${data?.address?.floor}
  тип доставки: ${data?.deliveryType},
  где сделан: ${data?.userSource},
  код аптеки: ${data?.source},
  заказ создан: ${data?.createdAt}`
    : `  телефон: ${data?.phone},
  номер заказа: ${data?.orderNumber},
  тип доставки: ${data?.deliveryType},
  где сделан: ${data?.userSource},
  код аптеки: ${data?.source},
  заказ создан: ${data?.createdAt}`;

  const getPharmacyTagBlock = (tag: PharmacyTag) => (
    <div
      style={{ color: tag.color }}
      className="pharmacy-tags__container__item"
    >
      <p>
        {t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.NAME')}{' '}
        <span style={{ color: tag.color }}>{tag.name}</span>
      </p>
      {tag.meta && (
        <p>
          {t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.META')}{' '}
          <span style={{ color: tag.color }}>{tag.meta}</span>
        </p>
      )}
    </div>
  );

  const FromOrTo = data?.reissued_to ? 'Переоформлено в' : 'Переоформлено c';

  const handleEdit = () => {
    if (data.paidDelivery === true) {
      return dispatch(
        actionsNotification.addApplicationNotification({
          content: 'Доставка оплачена, нельзя изменить способ',
          canBeClosed: true,
          type: 'error',
        })
      );
    }
    if (data.paymentType === PaymentType.IN_PLACE) {
      return dispatch(
        actionsNotification.addApplicationNotification({
          content: 'Необходимо сначала вид оплаты изменить на онлайн',
          canBeClosed: true,
          type: 'error',
        })
      );
    }
    setIsEditingDeliveryMethod(!isEditingDeliveryMethod);
  };
  const handleEditPayment = () => {
    if (
      Object.values(data.payments).some(
        (f) => f[0]?.payment_method === 'interpay'
      )
    ) {
      return dispatch(
        actionsNotification.addApplicationNotification({
          content: 'Нельзя менять способ оплаты для эквайринга',
          canBeClosed: true,
          type: 'error',
        })
      );
    }
    if (data.paymentOnSite) {
      return setIsEditingPaymentMethod(!isEditingPaymentMethod);
    }
    dispatch(
      actionsNotification.addApplicationNotification({
        content: 'В аптеке нет вида оплаты на месте',
        canBeClosed: true,
        type: 'error',
      })
    );
  };

  const isDisabledEditTag = data.sourceTags?.some((tag) =>
    tag.name.includes('disable_operator_edit_pharmacy_status')
  );

  return (
    <Styled.DetailLeftBlock id="detail">
      {(role === UserRole.ADMIN ||
        role === UserRole.OPERATOR ||
        role === UserRole.HEAD_OPERATOR) &&
        data &&
        data.promoCode &&
        data.promoCode.discount_target === 'delivery' && (
          <div className="notification-delivery">
            {t('OrdersPage.OrderDetailedModal.DELIVERY_PROMO_CODE')}
          </div>
        )}
      {(role === UserRole.ADMIN ||
        role === UserRole.OPERATOR ||
        role === UserRole.HEAD_OPERATOR) &&
        data &&
        data.bonusUsed && (
          <div className="notification-bonus">
            {t('OrdersPage.OrderDetailedModal.DELIVERY_BONUS')}
          </div>
        )}
      {(role === UserRole.ADMIN ||
        role === UserRole.OPERATOR ||
        role === UserRole.HEAD_OPERATOR) &&
        data &&
        data.promoCode &&
        data.promoCode.discount_target === 'products' && (
          <div className="notification-delivery">
            {t('OrdersPage.OrderDetailedModal.PRODUCTS_PROMO_CODE')}
          </div>
        )}
      <Styled.OrderGeneralInfo>
        <div className="order-number">{`Заказ ${data.orderNumber}`}</div>
        <div className="creation-date">{`От ${data.createdAt}`}</div>
        {(role === UserRole.OPERATOR ||
          role === UserRole.ADMIN ||
          role === UserRole.HEAD_OPERATOR) && (
          <>
            <div className="phone-number">
              <span> {t('OrdersPage.OrderDetailedModal.PHONE_TITLE')}</span>
              {data.phone}
              <span
                className="phone-copy-icon"
                onClick={() =>
                  handleCopyText(
                    data.phone,
                    t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.COPY_NUMBER')
                  )
                }
              >
                <img
                  src={copyIcon}
                  width="16px"
                  height="16px"
                  alt="copy icon"
                />
              </span>
            </div>
            <div className="creation-date">{data.userSource}</div>
            <div className="phone-number">{data.soft}</div>
            {(data?.reissued_to || data?.reissued_from) && (
              <div>
                <div
                  onClick={() => setIsOrdersVisible(!isOrdersVisible)}
                  className="general-info__title-option-reissue"
                >
                  {FromOrTo}
                  <img
                    src={bottomArrow}
                    alt="bottom arrow"
                    className={isOrdersVisible ? 'arrow-top' : 'arrow'}
                  />
                </div>

                {isOrdersVisible && data?.reissued_to && (
                  <div className="reissuedOrder">
                    {reissuedOrder?.map((item) => (
                      <NavLink to={`/orders/${item.id}`}>
                        <span className="reissuedOrder_number">
                          {item.orderNumber}
                          <span className="color">{item.sourceName}</span>
                        </span>
                      </NavLink>
                    ))}
                  </div>
                )}
                {isOrdersVisible && data?.reissued_from && (
                  <div className="reissuedOrder">
                    {reissuedOrderFrom?.map((item) => (
                      <NavLink target="_blank" to={`/orders/${item.id}`}>
                        <span className="reissuedOrder_number">
                          {item.orderNumber}
                          <span className="color">{item.sourceName}</span>
                        </span>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Styled.OrderGeneralInfo>
      <Styled.OrderDetailInfo>
        {isEditingDeliveryMethod && (
          <CustomDropDown
            title={t('OrdersPage.OrderDetailedModal.CHANGE_DELIVERY_TYPE')}
            defaultValue={
              data.deliveryType === DeliveryType.DELIVERY
                ? 'Курьер'
                : 'Самовывоз'
            }
            suggestionOptions={suggestionOptionsDelivery}
            onSave={() => {
              setIsEditingDeliveryMethod(!isEditingDeliveryMethod);
              handleChangeDeliveryMethod();
            }}
          />
        )}

        <div className="details-info__title-main">
          {t('OrdersPage.OrderDetailedModal.DETAILS_TITLE')}
        </div>
        <div className="delivery-type">
          <div className="delivery-type__title">
            {t('OrdersPage.OrderDetailedModal.DELIVERY_TYPE_TITLE')}
          </div>
          <div className="delivery-type__value">
            {deliveryTypeTitlesStrategy[data.deliveryType]}
            {(role === UserRole.ADMIN ||
              role === UserRole.OPERATOR ||
              role === UserRole.HEAD_OPERATOR) &&
              !isDisabledEditTag && (
                <span onClick={handleEdit}>
                  <img
                    width="22px"
                    height="22px"
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    src={editIcon}
                    alt="edit icon"
                  />
                </span>
              )}
          </div>
        </div>
        {(role === UserRole.ADMIN ||
          role === UserRole.OPERATOR ||
          role === UserRole.HEAD_OPERATOR) &&
          data.deliveryType === DeliveryType.DELIVERY &&
          data?.delivery &&
          data.delivery.slots && (
            <>
              <div
                className="details-info__title-option-delivery"
                onClick={() => setIsDetailsVisible(!isDetailsVisible)}
              >
                {t('OrdersPage.OrderDetailedModal.DELIVERY_BUTTON')}
                <img
                  src={bottomArrow}
                  alt="bottom arrow"
                  className={isDetailsVisible ? 'arrow-top' : 'arrow'}
                />
              </div>
              {isDetailsVisible && (
                <>
                  <p className="delivery-option__title">
                    {t('OrdersPage.OrderDetailedModal.DELIVERY_VARIANT')}
                  </p>
                  {deliveryOptionBlock(data?.delivery)}
                  <p className="delivery-option__title">
                    {t('OrdersPage.OrderDetailedModal.DELIVERY_SAW_VARIANT')}
                  </p>
                  <div className="delivery-option__another-options">
                    {data?.delivery.slots?.map((item) =>
                      deliveryOptionBlock(item)
                    )}
                  </div>
                  <div
                    className="hide"
                    onClick={() => setIsDetailsVisible(false)}
                  >
                    Cкрыть
                    <img
                      src={bottomArrowRed}
                      alt="bottom arrow"
                      className="arrow-top"
                    />
                  </div>
                </>
              )}
            </>
          )}
        {role !== UserRole.PHARMACIST && (
          <div className="pharmacy">
            <div className="pharmacy__title">
              {t('OrdersPage.OrderDetailedModal.PHARMACY_TITLE')}
            </div>
            <div>{`${data.sourceName}${
              data.sourceAddress ? `, ${data.sourceAddress}` : ''
            }`}</div>
          </div>
        )}

        {(role === UserRole.ADMIN ||
          role === UserRole.OPERATOR ||
          role === UserRole.HEAD_OPERATOR) && (
          <button className="re-register-btn" type="button">
            <a href={`https://daribar.kz/cart?${data.id}`}>
              {t('OrdersPage.OrderDetailedModal.RE_REGISTER')}
            </a>
          </button>
        )}
        {needsRecipe(data.products) &&
          role !== UserRole.MANAGER &&
          role === UserRole.PHARMACY_MANAGER &&
          role !== UserRole.PHARMACIST && (
            <div className="detail">
              <div className="detail__title">
                {t('OrdersPage.OrderDetailedModal.DETAIL_TITLE')}
              </div>
              <div> {t('OrdersPage.OrderDetailedModal.NEED_RECIPE')}</div>
            </div>
          )}
        {needsRecipe(data.products) && (
          <div className="pin-recipe">
            {(role === UserRole.OPERATOR ||
              role === UserRole.ADMIN ||
              role === UserRole.HEAD_OPERATOR) && (
              <div className="recipe__title">
                <span>{t('OrdersPage.OrderDetailedModal.RECIPE_TITLE')}</span>
                <div
                  onClick={handleClick}
                  onChange={handleChange}
                  className="pin"
                >
                  <img src={pencilIcon} alt="add recipe icon" />
                  <input type="file" id="upload-file" ref={hiddenFileInput} />
                </div>
              </div>
            )}
            {role === 'pharmacist' && (
              <div className="recipe__title">
                <span>{t('OrdersPage.OrderDetailedModal.RECIPE_TITLE2')}</span>
              </div>
            )}
            {imgLoading && <div className="file-name">Загрузка...</div>}
            {(imgRecipe || data.recipe) && !imgLoading && (
              <div className="file-name">
                {role === 'pharmacist' && (
                  <a href={data?.recipe}>
                    {t('OrdersPage.OrderDetailedModal.RECIPE_DOWNLOAD')}
                  </a>
                )}
                {(role === UserRole.OPERATOR ||
                  role === UserRole.ADMIN ||
                  role === UserRole.HEAD_OPERATOR) &&
                imgRecipe?.name &&
                dataImg ? (
                  <a href={dataImg}>{imgRecipe?.name}</a>
                ) : (
                  (role === UserRole.OPERATOR ||
                    role === UserRole.ADMIN ||
                    role === UserRole.HEAD_OPERATOR) && (
                    <a href={data?.recipe}>
                      {t('OrdersPage.OrderDetailedModal.RECIPE_DOWNLOAD')}
                    </a>
                  )
                )}
              </div>
            )}
          </div>
        )}
      </Styled.OrderDetailInfo>
      {(role === UserRole.OPERATOR ||
        role === UserRole.PHARMACIST ||
        role === UserRole.ADMIN ||
        role === UserRole.HEAD_OPERATOR) &&
        data.products.some((el) => el.sku.includes('_discount')) && (
          <Styled.DiscountProductsInfo>
            {data.products &&
            data.products.some((el) => isDateFresh(el.sku.split('_')[3]))
              ? t('OrdersPage.SALES_WITHOUT_DATES')
              : t('OrdersPage.EXPIRED_PRODUCT')}
          </Styled.DiscountProductsInfo>
        )}
      {(role === UserRole.OPERATOR ||
        role === UserRole.ADMIN ||
        role === UserRole.HEAD_OPERATOR) &&
        data.delivery &&
        data.deliveryType !== 'self' && (
          <Styled.OrderDetailInfo>
            <div className="details-info__title-main">
              {t('OrdersPage.OrderDetailedModal.DETAILS_DELIVERY')}
            </div>
            <div className="delivery-type__title-grey">
              {t('OrdersPage.OrderDetailedModal.PROVIDER')}{' '}
              <span
                className={
                  data.delivery.provider === 'choco'
                    ? 'delivery-type__choco'
                    : 'delivery-type__yandex'
                }
              >
                {data.delivery.provider === 'choco' ? 'Choco' : 'YandexGo'}
              </span>
            </div>
            <div className="delivery-type__title-grey">
              {t('OrdersPage.OrderDetailedModal.TYPE')}{' '}
              <span className="delivery-type__description-green">
                {typeDelivery(data.delivery.type)}
              </span>
            </div>

            {data.delivery.orderEta && (
              <div className="delivery-type__title-grey">
                {t('OrdersPage.OrderDetailedModal.DURATION')}{' '}
                <span>
                  ~ {data.delivery.orderEta}-{data.delivery.orderEta + 30} мин
                </span>
              </div>
            )}
            <div className="delivery-type__title-accent">
              {t('OrdersPage.OrderDetailedModal.PRICE')}{' '}
              <span
                className={
                  (data.promoCode &&
                    data.promoCode.discount_target === 'delivery') ||
                  data.bonusUsed
                    ? 'delivery-type__value-price'
                    : 'delivery-type__value-red'
                }
              >
                {data.delivery.price}₸
              </span>
            </div>
            {data.delivery.priceFact ? (
              <div className="delivery-type__title-accent">
                Фактическая стоимость:
                <span className="delivery-type__value">
                  {data.delivery.priceFact}₸
                </span>
              </div>
            ) : (
              ''
            )}

            {data.promoCode &&
              data.promoCode.discount_target === 'delivery' &&
              !data.bonusUsed && (
                <div className="delivery-type__title-accent">
                  {t('OrdersPage.OrderDetailedModal.PRICE_WITH_PROMOCODE')}
                  <span className="delivery-type__value-red">
                    {data.deliveryPriceWithDiscount
                      ? data.deliveryPriceWithDiscount
                      : 0}
                    ₸
                  </span>
                </div>
              )}
            {data.bonusUsed && !data.promoCode && (
              <div className="delivery-type__title-accent">
                С бонусом:
                <span className="delivery-type__value-red">
                  {data.deliveryPriceWithDiscount}₸
                </span>
              </div>
            )}
            {data.promoCode && data.bonusUsed && (
              <div className="delivery-type__title-accent">
                С промокодом и бонусом:
                <span className="delivery-type__value-red">
                  {data.deliveryPriceWithDiscount}₸
                </span>
              </div>
            )}
            {!data.promoCode && role === UserRole.ADMIN && (
              <>
                <div className="flex-container">
                  <input
                    placeholder="Введите промокод"
                    type="text"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    className={
                      promoCodeError
                        ? 'input-promocode-error'
                        : 'input-promocode'
                    }
                  />

                  <div onClick={handleApply} className="apply-promo-code">
                    Ok
                  </div>
                </div>
                {promoCodeError && (
                  <p className="error-message">{promoCodeError}</p>
                )}
              </>
            )}
            {data.promoCode &&
              data.promoCode.discount_target === 'delivery' &&
              (((role === UserRole.OPERATOR ||
                role === UserRole.HEAD_OPERATOR) &&
                data?.address?.city !== data?.city) ||
                role === UserRole.ADMIN) && (
                <>
                  <div
                    onClick={() => {
                      data.promoCode?.max_user_orders !== 1 &&
                        cancelPromocode(data.id);
                    }}
                    className={`cancel_button ${
                      data.promoCode?.max_user_orders === 1 && 'disabled'
                    } `}
                  >
                    Oтменить применение
                  </div>
                  {data.promoCode?.max_user_orders === 1 && (
                    <div className="error-message-promo">
                      Промокод на <b>первый заказ</b>, клиент не сможет
                      применить его повторно
                    </div>
                  )}
                </>
              )}
          </Styled.OrderDetailInfo>
        )}
      {(role === UserRole.OPERATOR ||
        role === UserRole.ADMIN ||
        role === UserRole.HEAD_OPERATOR) &&
        // !data.delivery &&
        data.promoCode &&
        data.promoCode.discount_target === 'delivery' && (
          <Styled.OrderDetailInfo>
            <div className="details-info__title-main">
              {t('OrdersPage.OrderDetailedModal.DETAILS_DELIVERY')}
            </div>
            <div className="details-info__title-promo">
              Применен промокод:
              <div className="details-info__title-promo-red">
                Промокод на доставку -
              </div>
              <div className="details-info__title-promo-red">
                {data.promoCode.value}
                {data.promoCode.discount_type === 'percent' ? '%' : '₸'} (
                {data.promoCode.code})
              </div>
            </div>
            {(((role === UserRole.OPERATOR ||
              role === UserRole.HEAD_OPERATOR) &&
              data?.address?.city !== data?.city) ||
              role === UserRole.ADMIN) && (
              <>
                <div
                  onClick={() => {
                    data.promoCode?.max_user_orders !== 1 &&
                      cancelPromocode(data.id);
                  }}
                  className={`cancel_button ${
                    data.promoCode?.max_user_orders === 1 && 'disabled'
                  } `}
                >
                  Oтменить применение
                </div>
                {data.promoCode?.max_user_orders === 1 && (
                  <div className="error-message-promo">
                    Промокод на <b>первый заказ</b>, клиент не сможет применить
                    его повторно
                  </div>
                )}
              </>
            )}
          </Styled.OrderDetailInfo>
        )}
      {(role === UserRole.OPERATOR ||
        role === UserRole.ADMIN ||
        role === UserRole.HEAD_OPERATOR) &&
        data.bonusUsed && (
          <Styled.OrderDetailInfo>
            <div className="details-info__title-main">
              {t('OrdersPage.OrderDetailedModal.DETAILS_DELIVERY')}
            </div>
            <div className="details-info__title-promo">
              Применены бонусы{data.promoCode ? ' и промокод:' : ':'}
              <div className="details-info__title-promo-red">
                Скидка на доставку -
              </div>
              <div className="details-info__title-promo-red">
                {Number(data.delivery.price || 0) -
                  Number(data.deliveryPriceWithDiscount || 0)}
              </div>
            </div>
          </Styled.OrderDetailInfo>
        )}
      {data.address.address !== '' && (
        <Styled.OrderDetailInfo>
          <div className="address">
            <div className="address__title">
              {role === UserRole.MANAGER ||
              role === UserRole.PHARMACIST ||
              role === UserRole.PHARMACY_MANAGER
                ? t('OrdersPage.OrderDetailedModal.ADDRESS_TITLE2')
                : t('OrdersPage.OrderDetailedModal.ADDRESS_TITLE')}
            </div>
            <div>
              {data.address.city ? `${data.address.city},` : null}
              {data.address.address ? `${data.address.address}` : null}
              {data.address.entrance
                ? `, подъезд ${data.address.entrance} `
                : null}
              {data.address.floor ? `, этаж ${data.address.floor} ` : null}
              {data.address.flat ? `, кв ${data.address.flat} ` : null}
            </div>
          </div>
        </Styled.OrderDetailInfo>
      )}
      <Styled.OrderDetailInfo>
        {data.comment && (
          <div className="comment">
            <div className="comment__title">
              {t('OrdersPage.OrderDetailedModal.COMMENT_TITLE')}
            </div>
            <div>{data.comment}</div>
          </div>
        )}
        {data.address && data.address.name && (
          <div className="comment">
            <div>{data.address.name}</div>
          </div>
        )}
        {isEditingPaymentMethod && (
          <CustomDropDown
            title={t('OrdersPage.OrderDetailedModal.CHANGE_PAYMENT_TYPE')}
            defaultValue={
              data.paymentType === PaymentType.KASPI_PAY ? 'Онлайн' : 'На месте'
            }
            suggestionOptions={suggestionOptions}
            onSave={() => {
              setIsEditingPaymentMethod(!isEditingPaymentMethod);
              handleChangePaymentMethod();
            }}
          />
        )}
        <div className="payment-type">
          <div className="payment-type__title">
            {t('OrdersPage.OrderDetailedModal.PAYMENT_TYPE_TITLE')}
          </div>
          <div className="payment-type__value">
            {paymentTypeTitlesStrategy[data.paymentType]}
            {(role === UserRole.ADMIN ||
              role === UserRole.OPERATOR ||
              role === UserRole.HEAD_OPERATOR) &&
              !isDisabledEditTag && (
                <span onClick={handleEditPayment}>
                  <img
                    width="22px"
                    height="22px"
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    src={editIcon}
                    alt="edit icon"
                  />
                </span>
              )}
          </div>
        </div>
        <div className="payment-type">
          <div className="payment-type__title">
            {t('OrdersPage.OrderDetailedModal.PAYMENT_STATUS_TYPE_TITLE')}
          </div>
          <div className="payment-type__value">
            {data.paymentType === PaymentType.IN_PLACE && '-'}
            {data.paymentType !== PaymentType.IN_PLACE &&
              (data.paid ? (
                <div className="payment-true-block">
                  <div>
                    <img className="success" src={success} alt="success" />
                    {t('OrdersPage.OrdersTable.PAYMENT.TRUE')}
                  </div>
                  <div className="was_paid_at">{data.wasPaidAt}</div>
                </div>
              ) : (
                <div className="payment-true-block">
                  {t('OrdersPage.OrdersTable.PAYMENT.FALSE')}
                </div>
              ))}
          </div>
        </div>
        {isCollectorVisible && (
          <div className="delivery-type">
            <div className="delivery-type__title">
              {t('OrdersPage.OrderDetailedModal.PROVIZOR')}
            </div>
            <div className="payment-type__value">
              {data.collector.phone ? (
                <div>
                  {data.collector.name} <br />
                  {data.collector.phone}
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        )}
        {(role === UserRole.ADMIN ||
          role === UserRole.OPERATOR ||
          role === UserRole.HEAD_OPERATOR) && (
          <div className="pharmacy-tags">
            {data.sourceTags && data.sourceTags?.length > 0 && (
              <p className="title">
                {' '}
                {t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.SOURCE_TAGS')}
              </p>
            )}
            <div className="pharmacy-tags__container">
              {data.sourceTags?.map((tag) => getPharmacyTagBlock(tag))}
            </div>
            {data.networkTags && data.networkTags?.length > 0 && (
              <p className="title">
                {t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.NETWORK_TAGS')}
              </p>
            )}
            <div className="pharmacy-tags__container">
              {data.networkTags?.map((tag: PharmacyTag) =>
                getPharmacyTagBlock(tag)
              )}
            </div>
            {(role === UserRole.OPERATOR ||
              role === UserRole.ADMIN ||
              role === UserRole.HEAD_OPERATOR) && (
              <>
                <Button
                  text={t('OrdersPage.OrderDetailedModal.PHARMACY_TAGS.COPY')}
                  variant="contained"
                  onClick={() =>
                    handleCopyText(
                      information,
                      t(
                        'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.ADDED_TO_BUFFER'
                      )
                    )
                  }
                />
                <div className="wa-number">
                  <div className="wa-editor-container">
                    <div>
                      {t(
                        'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.NUMBER_FOR_WHATSAPP'
                      )}
                    </div>
                    <div
                      onClick={() =>
                        setIsWhatsappEditorActive(!isWhatsappEditorActive)
                      }
                    >
                      <img
                        width="22px"
                        height="22px"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        src={editIcon}
                        alt="edit icon"
                      />
                    </div>
                  </div>
                  {isWhatsappEditorActive ? (
                    <>
                      <input
                        placeholder="Введите номер Whatsapp"
                        type="text"
                        value={whatsappNumber}
                        onChange={handleInputChange}
                        className="input"
                      />
                      {error && (
                        <p className="error-message">
                          {t(
                            'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.ENTER_CORRECT_NUMBER'
                          )}
                        </p>
                      )}
                      <Button
                        text="Сохранить"
                        variant="contained"
                        onClick={() => saveSwNumber()}
                      />
                    </>
                  ) : (
                    <p>{whatsappNumber}</p>
                  )}
                </div>
                <div className="wa-number">
                  <div className="wa-editor-container">
                    <div>
                      {t(
                        'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.NUMBER_FOR_KASPY'
                      )}
                    </div>
                    <div
                      onClick={() =>
                        setIsKaspiEditorActive(!isKaspiEditorActive)
                      }
                    >
                      <img
                        width="22px"
                        height="22px"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        src={editIcon}
                        alt="edit icon"
                      />
                    </div>
                  </div>
                  {isKaspiEditorActive ? (
                    <>
                      <input
                        placeholder="Введите номер Kaspi"
                        type="text"
                        value={kaspiNumber}
                        onChange={handleInputKaspiChange}
                        className="input"
                      />
                      {errorKaspi && (
                        <p className="error-message">
                          {t(
                            'OrdersPage.OrderDetailedModal.PHARMACY_TAGS.ENTER_CORRECT_NUMBER'
                          )}
                        </p>
                      )}

                      <Button
                        text="Сохранить"
                        variant="contained"
                        onClick={() => saveKaspiNumber()}
                      />
                    </>
                  ) : (
                    <p>{kaspiNumber}</p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Styled.OrderDetailInfo>
    </Styled.DetailLeftBlock>
  );
};

export default DetailLeftBlock;
